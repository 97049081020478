<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "jingPai",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#图2中画框处为需要识别的地方',
                        ],
                        img:[
                            "com/init.png",
                            "1.png",
                        ]
                    },
                    {
                        title: "关注残卷",
                        text:[
                            '#关注你要竞拍的残卷(图1)',
                            '#再关注一张任意残卷,你不拍的或者明天拍的,但是竞拍时间必须是排在第一张后面的残卷(图2)',
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                    {
                        title: "调整竞拍参数",
                        text:[
                            '#第一个参数根据自己家网速配置,在最后1秒时候,再延迟多少毫秒出价',
                            '#第二个参数是保证你出价后,怕被人顶价,可以设置连续出价次数(出价次数不可以设置太高,最多4次)',
                            '*建议:在最后竞拍的时候,用手机录一个慢动作视频,如果没有拍到,可以回放查找原因,适当调整以上参数',
                            '如果还没有出价,残卷就没了,那就是设置的延迟高了,需要降低延迟毫秒,如果出价后还是被人顶价了,可以提高出价次数',
                            '*出价一次的时间大概是10毫秒左右',
                        ],
                        img:[
                            "3.png",
                        ]
                    },
                    {
                        title: "开始竞拍",
                        text:[
                            '#以上设置好后,点击开始,日志提示"准备就绪"说明成功开始,等待竞拍即可',
                            '#如果一直不出现"准备就绪",检查第1步或包里的银子够不够',
                        ],
                        img:[
                            "4.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
